import React from "react";
import {
  Box,
  Text,
  Image,
  Stack,
  SimpleGrid,
  Flex,
  Progress,
} from "@chakra-ui/react";
import { FormatNumber } from "../FormatNumber"; // Ensure this path is correct

// Import the placeholder image
import placeholder from "../../assets/placeholder.jpg";

const Overview = ({ data }) => {
  if (!data) return null;

  const {
    budget = 0,
    cpm = 0,
    name = "",
    description = "",
    imageURL,
    creators = [],
    posts = [],
  } = data;

  // Create a Set of valid creator IDs
  const creatorIdsSet = new Set(creators.map((creator) => creator.creatorId));

  // Initialize totals
  let totalImpressions = 0;

  // Aggregate data from posts
  if (posts && posts.length > 0) {
    posts.forEach((post) => {
      const { creatorId, impressions } = post;
      if (creatorIdsSet.has(creatorId)) {
        totalImpressions += impressions || 0;
      }
    });
  }

  // Calculate creators with posts
  const totalCreators = creators.length;
  const creatorsWithPostsCount = creators.filter((creator) =>
    posts.some((post) => post.creatorId === creator.creatorId)
  ).length;

  // Calculate expected impressions based on budget and CPM
  const totalExpectedImpressions =
    cpm && budget ? Math.round((budget / cpm) * 1000) : 0;

  // Calculate progress percentages
  const creatorsProgress =
    totalCreators > 0 ? (creatorsWithPostsCount / totalCreators) * 100 : 0;
  const impressionsProgress =
    totalExpectedImpressions > 0
      ? (totalImpressions / totalExpectedImpressions) * 100
      : 0;

  // Cap progress at 100%
  const cappedCreatorsProgress = Math.min(creatorsProgress, 100);
  const cappedImpressionsProgress = Math.min(impressionsProgress, 100);

  return (
    <Box maxW="1200px" mx="auto" p={4}>
      <Stack py={6} spacing={4}>
        <Flex align="start">
          <Image
            src={imageURL || placeholder}
            alt={`${name} Image`}
            borderRadius="md"
            objectFit="cover"
            width="100px"
            height="100px"
            boxShadow="sm"
            mr={4}
          />
          <Box>
            <Text fontSize="xl" fontWeight="bold">
              {name}
            </Text>
            <Text fontSize="lg">
              {description || "No description provided."}
            </Text>
          </Box>
        </Flex>
      </Stack>

      {/* Tracking Statistics */} 
      <SimpleGrid columns={[1, 2, 4]} spacing={4} textAlign="left">

        {/* CPM */}
        <Box p={4} borderRadius="xl" borderWidth="1px" >
          <Text >CPM</Text>
          <Text fontSize="2xl" fontWeight="bold">{`$${FormatNumber(cpm)}`}</Text>
        </Box>

        {/* Total Impressions */}
        <Box p={4} borderRadius="xl" borderWidth="1px">
          <Text >Total Impressions</Text>
          <Text fontSize="2xl" fontWeight="bold">{FormatNumber(totalImpressions)}</Text>
        </Box>

        {/* Creators with Posts */}
        <Box p={4} borderRadius="xl" borderWidth="1px">
          <Text >Creators with Posts</Text>
          <Text fontSize="2xl" fontWeight="bold">
            {`${FormatNumber(creatorsWithPostsCount)} / ${FormatNumber(
              totalCreators
            )}`}
          </Text>
          <Progress
            mt={2}
            value={cappedCreatorsProgress}
            colorScheme={cappedCreatorsProgress >= 100 ? "green" : "blue"}
            size="md"
            borderRadius="full"
          />
        </Box>

        {/* Impressions Achieved */}
        <Box p={4} borderRadius="xl" borderWidth="1px">
          <Text >Impressions Achieved</Text>
          <Text fontWeight="bold" fontSize="2xl">
            {`${FormatNumber(totalImpressions)} / ${FormatNumber(
              totalExpectedImpressions
            )}`}
          </Text>
          <Progress
            mt={2}
            value={cappedImpressionsProgress}
            colorScheme={cappedImpressionsProgress >= 100 ? "green" : "blue"}
            size="md"
            borderRadius="full"
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Overview;
