import React, { useState } from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Grid,
  GridItem,
  IconButton,
  Input,
  HStack,
  Link, // 1. Import the Link component
} from '@chakra-ui/react';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { FormatNumber } from './FormatNumber';
import IGFollowers from './IGFollowers';
import TikTokFollowers from './TikTokFollowers';
import Deliverables from './Deliverables';
import verifiedBadge from '../../assets/verifiedbadge.png';

const CreatorCard = ({
  creator,
  visibleColumns,
  allowEdit,
  onToggleFavorite,
  onNoteChange,
  onDeliverablesChange,
}) => {
  const [editingNote, setEditingNote] = useState(false);
  const [note, setNote] = useState(creator.notes || '');

  const handleNoteClick = () => {
    if (allowEdit.notes) {
      setEditingNote(true);
    }
  };

  const handleNoteBlur = () => {
    setEditingNote(false);
    onNoteChange(creator.creatorId, note);
  };

  const calculateTotalImpressions = () => {
    let total = 0;
    creator.deliverables.forEach((deliverable) => {
      switch (deliverable.type) {
        case 'Instagram Post':
          total +=
            creator.creatorInfo.instagram_profile.projected_post_views *
            deliverable.count;
          break;
        case 'Instagram Story':
          total +=
            creator.creatorInfo.instagram_profile.projected_story_views *
            deliverable.count;
          break;
        case 'Instagram Reel':
          total +=
            creator.creatorInfo.instagram_profile.projected_reel_views *
            deliverable.count;
          break;
        case 'TikTok Video':
          total +=
            (creator.creatorInfo.tiktok_profile?.projected_video_views || 0) *
            deliverable.count;
          break;
        default:
          break;
      }
    });
    return total;
  };

  // Collect metric GridItems
  const metrics = [];

  if (visibleColumns.IGPostImpressions) {
    metrics.push(
      <GridItem key="IGPostImpressions">
        <Box
          border="1px solid #e2e8f0"
          p={2}
          borderRadius="xl"
          textAlign="center"
          fontSize="13px"
        >
          Post Impressions
          <br />
          <Text fontSize="lg" fontWeight="bold">
            {FormatNumber(
              creator.creatorInfo.instagram_profile.projected_post_views
            )}
          </Text>
        </Box>
      </GridItem>
    );
  }

  if (visibleColumns.IGStoriesImpressions) {
    metrics.push(
      <GridItem key="IGStoriesImpressions">
        <Box
          border="1px solid #e2e8f0"
          p={2}
          borderRadius="xl"
          textAlign="center"
          fontSize="13px"
        >
          Story Impressions
          <br />
          <Text fontSize="lg" fontWeight="bold">
            {FormatNumber(
              creator.creatorInfo.instagram_profile.projected_story_views
            )}
          </Text>
        </Box>
      </GridItem>
    );
  }

  if (visibleColumns.IGReelsImpressions) {
    metrics.push(
      <GridItem key="IGReelsImpressions">
        <Box
          border="1px solid #e2e8f0"
          p={2}
          borderRadius="xl"
          textAlign="center"
          fontSize="13px"
        >
          Reel Impressions
          <br />
          <Text fontSize="lg" fontWeight="bold">
            {FormatNumber(
              creator.creatorInfo.instagram_profile.projected_reel_views
            )}
          </Text>
        </Box>
      </GridItem>
    );
  }

  if (visibleColumns.TikTokPostImpressions) {
    metrics.push(
      <GridItem key="TikTokPostImpressions">
        <Box
          border="1px solid #e2e8f0"
          p={2}
          borderRadius="xl"
          textAlign="center"
          fontSize="13px"
        >
          TikTok Impressions
          <br />
          <Text fontSize="lg" fontWeight="bold">
            {FormatNumber(
              creator.creatorInfo.tiktok_profile?.projected_video_views || 0
            )}
          </Text>
        </Box>
      </GridItem>
    );
  }

  if (visibleColumns.engagementRate) {
    metrics.push(
      <GridItem key="engagementRate">
        <Box
          border="1px solid #e2e8f0"
          p={2}
          borderRadius="xl"
          textAlign="center"
          fontSize="13px"
        >
          Eng. Rate <br />
          <Text fontSize="lg" fontWeight="bold">
            {(creator.creatorInfo.instagram_profile.engagement_rate * 100).toFixed(2)}%
          </Text>
        </Box>
      </GridItem>
    );
  }

  if (visibleColumns.expectedImpressions) {
    metrics.push(
      <GridItem key="expectedImpressions">
        <Box
          border="1px solid #e2e8f0"
          p={2}
          borderRadius="xl"
          textAlign="center"
          fontSize="13px"
        >
          Total Impressions
          <br />
          <Text fontSize="lg" fontWeight="bold">
            {FormatNumber(calculateTotalImpressions())}
          </Text>
        </Box>
      </GridItem>
    );
  }

  // Adjust the last GridItem if the number of metrics is odd
  if (metrics.length % 2 !== 0 && metrics.length > 0) {
    const lastMetric = metrics[metrics.length - 1];
    metrics[metrics.length - 1] = React.cloneElement(lastMetric, {
      colSpan: 2,
    });
  }

  return (
    <Box
      borderRadius="xl"
      bg="#F4F5F7"
      overflow="hidden"
      p={5}
      border="1px solid #e2e8f0"
      position="relative"
    >
      {visibleColumns.favorite && (
        <IconButton
          icon={creator.isFavorite ? <FaStar color="#fcba03" /> : <FaRegStar />}
          onClick={() => onToggleFavorite(creator.creatorId)}
          aria-label="Toggle Favorite"
          bg="#F4F5F7"
          fontSize="24px"
          position="absolute"
          top="10px"
          left="10px"
          zIndex={1000}
          isDisabled={!allowEdit.favorite}
        />
      )}

      {visibleColumns.creator && (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box position="relative">
              <Image
                src={creator.creatorInfo.instagram_profile.profile_pic_url}
                alt={creator.creatorInfo.name}
                borderRadius="full"
                boxSize="100px"
                objectFit="cover"
              />
              {creator.creatorInfo.instagram_profile.is_verified && (
                <Image
                  src={verifiedBadge}
                  alt="Verified"
                  boxSize="30px"
                  position="absolute"
                  top={0}
                  left={0}
                  zIndex={10}
                />
              )}
            </Box>
          </Box>

          <Heading as="h2" size="md" mt={4} textAlign="center">
            {creator.creatorInfo.name}
          </Heading>
          
          {/* 2. Conditionally render the username as a link if hoobe_handle exists */}
          <Heading as="h3" size="sm" mt={2} textAlign="center" fontWeight="400">
            {creator.creatorInfo.hoobe_handle ? (
              <Link
                href={`https://hoo.be/${creator.creatorInfo.hoobe_handle}`}
                color="blue.500"
                isExternal
                aria-label={`Hoo.be profile of ${creator.creatorInfo.instagram_profile.username}`}
              >
                @{creator.creatorInfo.instagram_profile.username}
              </Link>
            ) : (
              <Text
                aria-label={`Instagram profile of ${creator.creatorInfo.instagram_profile.username}`}
              >
                @{creator.creatorInfo.instagram_profile.username}
              </Text>
            )}
          </Heading>
        </>
      )}

      {visibleColumns.category && (
        <Text as="h3" color="gray.400" fontSize="16px" mt={2} textAlign="center">
          {creator.creatorInfo.instagram_profile.category}
        </Text>
      )}

      {/* Centered Followers Section */}
      <HStack spacing={4} justify="center" mt={2}>
        {visibleColumns.IGFollowers && (
          <Box textAlign="center" fontSize="sm">
            <IGFollowers
              followersCount={creator.creatorInfo.instagram_profile.followers_count}
              username={creator.creatorInfo.instagram_profile.username}
            />
          </Box>
        )}

        {visibleColumns.TikTokFollowers && creator.creatorInfo.tiktok_profile && creator.creatorInfo.tiktok_profile.followers_count && creator.creatorInfo.tiktok_profile.username && (
          <Box textAlign="center" fontSize="sm">
            <TikTokFollowers
              followersCount={creator.creatorInfo.tiktok_profile.followers_count}
              username={creator.creatorInfo.tiktok_profile.username}
            />
          </Box>
        )}
      </HStack>

      {metrics.length > 0 && (
        <Grid mt={4} templateColumns="repeat(2, 1fr)" gap={2}>
          {metrics}
        </Grid>
      )}

      {visibleColumns.deliverables && (
        <Box mt={2}>
          <Deliverables
            initialDeliverables={creator.deliverables}
            onChange={(updatedDeliverables) =>
              onDeliverablesChange(creator.creatorId, updatedDeliverables)
            }
            isEditable={allowEdit.deliverables}
          />
        </Box>
      )}

      {visibleColumns.notes && (
        <Box mt={2}>
          <Box
            border="1px solid #e2e8f0"
            borderRadius="xl"
            bg="white"
            onClick={handleNoteClick}
          >
            {editingNote && allowEdit.notes ? (
              <Input
                value={note}
                onChange={(e) => setNote(e.target.value)}
                onBlur={handleNoteBlur}
                placeholder="Add a note..."
                autoFocus
                borderRadius="xl"
              />
            ) : (
              <Text p={2} color={note ? 'black' : 'gray.400'}>
                {note || 'Add a note...'}
              </Text>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreatorCard;
