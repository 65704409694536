import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Lists from "./components/Lists";
import Campaigns from "./components/Campaigns";

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    blue: {
      300: "#b0e6eb",
      400: "#66cbd4",
      500: "#5cb5bd",
      600: "#44939a",
    },
  },
  styles: {
    global: {
      'html, body': {
        overflowY: "scroll",
      },
      '::-webkit-scrollbar': {
        display: "none",
      },
      'html': {
        msOverflowStyle: "none",
      },
      'body': {
        scrollbarWidth: "none",
      },
    },
  },
});

function Redirect() {
  useEffect(() => {
    console.log("Not on a valid url, redirecting...")
    // window.location.href = "https://hoobe.me";
  }, []);

  return null;
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/lists/:shareId" element={<Lists />} />
          <Route path="/campaigns/:shareId" element={<Campaigns />} />
          <Route path="*" element={<Redirect />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
