// FormatNumber.js

export const FormatNumber = (number) => {
  if (!number && number !== 0) return "N/A";

  const trillion = 1000000000000;
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  let formattedCount;

  if (number >= trillion) {
    formattedCount = number >= 10 * trillion 
      ? Math.floor(number / trillion) + "T"
      : (number / trillion).toFixed(1) + "T";
  } else if (number >= billion) {
    formattedCount = number >= 10 * billion 
      ? Math.floor(number / billion) + "B"
      : (number / billion).toFixed(1) + "B";
  } else if (number >= million) {
    formattedCount = number >= 10 * million 
      ? Math.floor(number / million) + "M"
      : (number / million).toFixed(1) + "M";
  } else if (number >= thousand) {
    formattedCount = number >= 100 * thousand 
      ? Math.floor(number / thousand) + "K"
      : (number / thousand).toFixed(1) + "K";
  } else {
    return number.toString();
  }

  return formattedCount.replace('.0', '');
};