import React, { useEffect, useState } from "react";
import { Box, Text, Spinner, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import ShareCards from "./share/ShareCards";

const Lists = () => {
  const { shareId } = useParams();
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({ email: null, uid: null, authToken: null });

  useEffect(() => {
    const uid = Cookies.get("userUid");
    const email = Cookies.get("userEmail");
    const authToken = Cookies.get("authToken");

    if (email && uid && authToken) {
      setUser({ email, uid, authToken });
    } else {
      setError("No user information found in cookies");
    }
  }, []);

  useEffect(() => {
    if (shareId) {
      fetch(`${process.env.REACT_APP_HOOBE_API_URL}/share-list`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ shareId }),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponseData(data);
          setLoading(false);
        })
        .catch(() => {
          setError("Error fetching data from the server");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [shareId]);

  console.log(user);

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box>
      {shareId ? (
        responseData ? (
          <ShareCards data={responseData} />
        ) : error ? (
          <Text color="red.500">{error}</Text>
        ) : (
          <Text>Loading...</Text>
        )
      ) : (
        <Text>No Share ID found</Text>
      )}

      {/* {shareId ? (
        responseData ? (
          <Box as="pre" p={4} bg="gray.50" borderRadius="md" overflowX="auto">
            {JSON.stringify(responseData, null, 2)}
          </Box>
        ) : error ? (
          <Text color="red.500">{error}</Text>
        ) : (
          <Text>Loading...</Text>
        )
      ) : (
        <Text>No Share ID found</Text>
      )} */}

      {/* {user.email && user.uid ? (
        <Box mb={4}>
          <Text>
            <strong>User Email:</strong> {user.email}
          </Text>
          <Text>
            <strong>User UID:</strong> {user.uid}
          </Text>
          <Text>
            <strong>Auth Token:</strong> {user.authToken}
          </Text>
        </Box>
      ) : (
        <Text>Loading user data from cookies...</Text>
      )} */}
    </Box>
  );
};

export default Lists;
