import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  SimpleGrid,
  useToast,
  Input,
  VStack,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import CreatorCard from "./CreatorCard";
import Overview from "./Overview";
import Header from "./Header";

const ShareCards = ({ data }) => {
  const { shareId } = useParams();
  const [creators, setCreators] = useState(data.listData.creators);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const visibleColumns = data.shareData.visibleColumns;
  const allowEdit = data.shareData.allowEdit;
  const toast = useToast();

  const updateCreatorData = async (creatorId, updates) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOOBE_API_URL}/share-save`,
        {
          shareId,
          creatorId,
          ...updates,
        }
      );

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.error("Error updating creator data:", error);
      toast({
        title: "Error updating creator data",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleToggleFavorite = async (creatorId) => {
    if (allowEdit.favorite) {
      const creator = creators.find((c) => c.creatorId === creatorId);
      const newFavoriteStatus = !creator.isFavorite;
      const success = await updateCreatorData(creatorId, {
        isFavorite: newFavoriteStatus,
      });
      if (success) {
        setCreators((prevCreators) =>
          prevCreators.map((c) =>
            c.creatorId === creatorId
              ? { ...c, isFavorite: newFavoriteStatus }
              : c
          )
        );
      }
    }
  };

  const handleNoteChange = async (creatorId, updatedNote) => {
    const success = await updateCreatorData(creatorId, { notes: updatedNote });
    if (success) {
      setCreators((prevCreators) =>
        prevCreators.map((c) =>
          c.creatorId === creatorId ? { ...c, notes: updatedNote } : c
        )
      );
    }
  };

  // eslint-disable-next-line
  const debouncedUpdateDeliverables = useCallback(
    debounce((creatorId, updatedDeliverables) => {
      updateCreatorData(creatorId, { deliverables: updatedDeliverables });
    }, 3000),
    [shareId]
  );

  const handleDeliverablesChange = (creatorId, updatedDeliverables) => {
    setCreators((prevCreators) =>
      prevCreators.map((c) =>
        c.creatorId === creatorId
          ? { ...c, deliverables: updatedDeliverables }
          : c
      )
    );
    debouncedUpdateDeliverables(creatorId, updatedDeliverables);
  };

  const getDeliverableCount = (creator, type) => {
    return creator.deliverables
      ? creator.deliverables
          .filter((d) => d.type === type)
          .reduce((sum, d) => sum + (d.count || 0), 0)
      : 0;
  };

  const filteredCreators = useMemo(() => {
    let filtered = creators.filter((creator) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        creator.creatorInfo.name.toLowerCase().includes(searchLower) ||
        (creator.creatorInfo.instagram_profile &&
          creator.creatorInfo.instagram_profile.username
            .toLowerCase()
            .includes(searchLower)) ||
        (creator.creatorInfo.instagram_profile &&
          creator.creatorInfo.instagram_profile.name
            .toLowerCase()
            .includes(searchLower))
      );
    });

    // Apply sorting based on sortOrder
    switch (sortOrder) {
      case "followersHighToLow":
        if (visibleColumns.IGFollowers) {
          filtered.sort(
            (a, b) =>
              ((b.creatorInfo.instagram_profile &&
                b.creatorInfo.instagram_profile.followers_count) ||
                0) -
              ((a.creatorInfo.instagram_profile &&
                a.creatorInfo.instagram_profile.followers_count) ||
                0)
          );
        }
        break;
      case "followersLowToHigh":
        if (visibleColumns.IGFollowers) {
          filtered.sort(
            (a, b) =>
              ((a.creatorInfo.instagram_profile &&
                a.creatorInfo.instagram_profile.followers_count) ||
                0) -
              ((b.creatorInfo.instagram_profile &&
                b.creatorInfo.instagram_profile.followers_count) ||
                0)
          );
        }
        break;
      case "igStoriesImpressionsHighToLow":
        if (visibleColumns.IGStoriesImpressions) {
          filtered.sort((a, b) => {
            const aProjectedViews =
              (a.creatorInfo.instagram_profile &&
                a.creatorInfo.instagram_profile.projected_story_views) ||
              0;
            const bProjectedViews =
              (b.creatorInfo.instagram_profile &&
                b.creatorInfo.instagram_profile.projected_story_views) ||
              0;
            const aImpressions =
              aProjectedViews * getDeliverableCount(a, "Instagram Story");
            const bImpressions =
              bProjectedViews * getDeliverableCount(b, "Instagram Story");
            return bImpressions - aImpressions;
          });
        }
        break;
      case "igReelsImpressionsHighToLow":
        if (visibleColumns.IGReelsImpressions) {
          filtered.sort((a, b) => {
            const aProjectedViews =
              (a.creatorInfo.instagram_profile &&
                a.creatorInfo.instagram_profile.projected_reel_views) ||
              0;
            const bProjectedViews =
              (b.creatorInfo.instagram_profile &&
                b.creatorInfo.instagram_profile.projected_reel_views) ||
              0;
            const aImpressions =
              aProjectedViews * getDeliverableCount(a, "Instagram Reel");
            const bImpressions =
              bProjectedViews * getDeliverableCount(b, "Instagram Reel");
            return bImpressions - aImpressions;
          });
        }
        break;
      case "igPostImpressionsHighToLow":
        if (visibleColumns.IGPostImpressions) {
          filtered.sort((a, b) => {
            const aProjectedViews =
              (a.creatorInfo.instagram_profile &&
                a.creatorInfo.instagram_profile.projected_post_views) ||
              0;
            const bProjectedViews =
              (b.creatorInfo.instagram_profile &&
                b.creatorInfo.instagram_profile.projected_post_views) ||
              0;
            const aImpressions =
              aProjectedViews * getDeliverableCount(a, "Instagram Post");
            const bImpressions =
              bProjectedViews * getDeliverableCount(b, "Instagram Post");
            return bImpressions - aImpressions;
          });
        }
        break;
      case "tiktokPostImpressionsHighToLow":
        if (visibleColumns.TikTokPostImpressions) {
          filtered.sort((a, b) => {
            const aProjectedViews =
              (a.creatorInfo.tiktok_profile &&
                a.creatorInfo.tiktok_profile.projected_video_views) ||
              0;
            const bProjectedViews =
              (b.creatorInfo.tiktok_profile &&
                b.creatorInfo.tiktok_profile.projected_video_views) ||
              0;
            const aImpressions =
              aProjectedViews * getDeliverableCount(a, "TikTok Video");
            const bImpressions =
              bProjectedViews * getDeliverableCount(b, "TikTok Video");
            return bImpressions - aImpressions;
          });
        }
        break;
      case "engagementRateHighToLow":
        if (visibleColumns.engagementRate) {
          filtered.sort((a, b) => {
            const aEngagementRate =
              (a.creatorInfo.instagram_profile &&
                a.creatorInfo.instagram_profile.engagement_rate) ||
              (a.creatorInfo.tiktok_profile &&
                a.creatorInfo.tiktok_profile.engagement_rate) ||
              0;
            const bEngagementRate =
              (b.creatorInfo.instagram_profile &&
                b.creatorInfo.instagram_profile.engagement_rate) ||
              (b.creatorInfo.tiktok_profile &&
                b.creatorInfo.tiktok_profile.engagement_rate) ||
              0;
            return bEngagementRate - aEngagementRate;
          });
        }
        break;
      case "nameAToZ":
        filtered.sort((a, b) =>
          a.creatorInfo.name.localeCompare(b.creatorInfo.name)
        );
        break;
      case "nameZToA":
        filtered.sort((a, b) =>
          b.creatorInfo.name.localeCompare(a.creatorInfo.name)
        );
        break;
      default:
        break;
    }

    return filtered;
  }, [creators, searchTerm, sortOrder, visibleColumns]);

  const totalImpressions = useMemo(() => {
    return creators.reduce((total, creator) => {
      const creatorTotal = creator.deliverables.reduce(
        (creatorSum, deliverable) => {
          let impressions = 0;
          switch (deliverable.type) {
            case "Instagram Post":
              impressions =
                ((creator.creatorInfo.instagram_profile &&
                  creator.creatorInfo.instagram_profile.projected_post_views) ||
                  0) * deliverable.count;
              break;
            case "Instagram Story":
              impressions =
                ((creator.creatorInfo.instagram_profile &&
                  creator.creatorInfo.instagram_profile.projected_story_views) ||
                  0) * deliverable.count;
              break;
            case "Instagram Reel":
              impressions =
                ((creator.creatorInfo.instagram_profile &&
                  creator.creatorInfo.instagram_profile.projected_reel_views) ||
                  0) * deliverable.count;
              break;
            case "TikTok Video":
              impressions =
                ((creator.creatorInfo.tiktok_profile &&
                  creator.creatorInfo.tiktok_profile.projected_video_views) ||
                  0) * deliverable.count;
              break;
            default:
              break;
          }
          return creatorSum + impressions;
        },
        0
      );
      return total + creatorTotal;
    }, 0);
  }, [creators]);

  const isSortVisible =
    visibleColumns.IGFollowers ||
    visibleColumns.IGStoriesImpressions ||
    visibleColumns.IGReelsImpressions ||
    visibleColumns.IGPostImpressions ||
    visibleColumns.TikTokPostImpressions ||
    visibleColumns.engagementRate;

  return (
    <Box>
      <Header listData={data.listData} visibleColumns={visibleColumns} />
      <Box p={4} maxW="1400px" mx="auto" mb={100}>
        <VStack spacing={4} align="stretch">
          <Overview
            listData={data.listData}
            totalImpressions={totalImpressions}
          />
          <Box display="flex" width="100%" alignItems="center">
            <Input
              placeholder="Search creators..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="lg"
              borderRadius="xl"
              flex="1"
            />
            {isSortVisible && (
              <Select
                placeholder="Sort by"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                size="lg"
                borderRadius="xl"
                width="250px"
                ml={4}
              >
                {visibleColumns.IGFollowers && (
                  <>
                    <option value="followersHighToLow">
                      Followers: High to Low
                    </option>
                    <option value="followersLowToHigh">
                      Followers: Low to High
                    </option>
                  </>
                )}
                {visibleColumns.IGStoriesImpressions && (
                  <option value="igStoriesImpressionsHighToLow">
                    IG Stories Impressions: High to Low
                  </option>
                )}
                {visibleColumns.IGReelsImpressions && (
                  <option value="igReelsImpressionsHighToLow">
                    IG Reels Impressions: High to Low
                  </option>
                )}
                {visibleColumns.IGPostImpressions && (
                  <option value="igPostImpressionsHighToLow">
                    IG Post Impressions: High to Low
                  </option>
                )}
                {visibleColumns.TikTokPostImpressions && (
                  <option value="tiktokPostImpressionsHighToLow">
                    TikTok Post Impressions: High to Low
                  </option>
                )}
                {visibleColumns.engagementRate && (
                  <option value="engagementRateHighToLow">
                    Engagement Rate: High to Low
                  </option>
                )}
                <option value="nameAToZ">Name: A-Z</option>
                <option value="nameZToA">Name: Z-A</option>
              </Select>
            )}
          </Box>
          <SimpleGrid columns={[1, 1, 2, 4]} spacing={4}>
            {filteredCreators.map((creator) => (
              <CreatorCard
                key={creator.creatorId}
                creator={creator}
                visibleColumns={visibleColumns}
                allowEdit={allowEdit}
                onToggleFavorite={handleToggleFavorite}
                onNoteChange={handleNoteChange}
                onDeliverablesChange={handleDeliverablesChange}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Box>
    </Box>
  );
};

export default ShareCards;
