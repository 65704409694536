// CampaignPosts.js

import React, { useState, useMemo } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Image,
  VStack,
  HStack,
  Avatar,
  Select,
  Grid,
  GridItem,
  Input,
  Center,
  Button,
  Link,
} from "@chakra-ui/react";
import { FormatNumber } from "../FormatNumber"; // Import the FormatNumber function

// Import icons and images
import instagramIcon from "../../assets/instagrampost.png";
import instagramReel from "../../assets/instagramreel.png";
import instagramStory from "../../assets/instagramstory.png";
import tiktokPostIcon from "../../assets/tiktok.png";
import placeholderImage from "../../assets/placeholder.jpg";
import verifiedBadge from "../../assets/verifiedbadge.png";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const CampaignPosts = ({ data }) => {
  // **Memoize 'posts' to ensure referential stability**
  const posts = useMemo(
    () => data?.campaignDetails?.posts || [],
    [data?.campaignDetails?.posts]
  );

  // **State for Sorting and Filtering**
  const [sortKey, setSortKey] = useState("username");
  const [filter, setFilter] = useState("");

  const handleSortChange = (e) => {
    setSortKey(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Memoize filtered and sorted posts for performance
  const sortedPosts = useMemo(() => {
    // **Filter posts by username**
    const filtered = posts.filter((post) =>
      post.username.toLowerCase().includes(filter.toLowerCase())
    );

    // **Sort the filtered posts**
    const sorted = [...filtered];
    sorted.sort((a, b) => {
      if (sortKey === "username") {
        return a.username.localeCompare(b.username);
      } else if (sortKey === "impressions") {
        return a.impressions - b.impressions;
      } else if (sortKey === "impressions_desc") {
        return b.impressions - a.impressions;
      }
      return 0;
    });
    return sorted;
  }, [posts, sortKey, filter]);

  // **Conditional Rendering After Hooks**
  if (!posts || posts.length === 0) {
    return (
      <Center width="100%" height="100vh">
        <Text>No posts available.</Text>
      </Center>
    );
  }

  // Function to get the appropriate icon based on post type
  const getPostTypeIcon = (post) => {
    if (post.platform.toLowerCase() === "instagram") {
      if (post.type === "reel") {
        return instagramReel;
      } else if (post.type === "story") {
        return instagramStory;
      } else {
        return instagramIcon;
      }
    } else if (post.platform.toLowerCase() === "tiktok") {
      return tiktokPostIcon;
    }
    return null;
  };

  return (
    <Box width="100%" maxW="1200px" mx="auto" p={4} mb={100}>
      {/* Sorting and Filtering Inputs */}
      <HStack mb={6} spacing={4}>
        <Input
          placeholder="Filter by username"
          value={filter}
          onChange={handleFilterChange}
          size="lg"
        />
        <Select
          placeholder="Sort By"
          value={sortKey}
          onChange={handleSortChange}
          maxW="200px"
          size="lg"
        >
          <option value="username">Username (A-Z)</option>
          <option value="impressions">Impressions (Low to High)</option>
          <option value="impressions_desc">Impressions (High to Low)</option>
        </Select>
      </HStack>

      {/* Posts Grid */}
      <SimpleGrid columns={[1, 2, 3, 4]} spacing={4}>
        {sortedPosts.map((post) => (
          <Box
            key={
              post.url || post.createdAt || `${post.username}-${post.createdAt}`
            }
            borderWidth="1px"
            borderRadius="xl"
            overflow="hidden"
          >
            {/* Post Content */}
            <Link href={post.content_url} isExternal>
              <Image
                src={post.thumbnail_url || placeholderImage}
                alt={post.caption || "Post Image"}
                objectFit="cover"
                width="100%"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImage;
                }}
              />
            </Link>

            <VStack align="start" p={4} spacing={2}>
              {/* Source Information */}
              <HStack spacing={2} mt={2}>
                <Box position="relative">
                  <Avatar
                    size="sm"
                    src={post.profile_pic_url}
                    name={post.name}
                  />
                  {post.is_verified && (
                    <Image
                      src={verifiedBadge}
                      alt="Verified Badge"
                      boxSize="12px"
                      position="absolute"
                      top="-2px"
                      left="-2px"
                    />
                  )}
                </Box>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold" fontSize="sm">
                    {post.name}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    @{post.username}
                  </Text>
                </VStack>
              </HStack>

              {/* Caption */}
              {post.caption && (
                <Text fontSize="sm" noOfLines={3}>
                  {post.caption}
                </Text>
              )}

              {/* View Media Button */}
              <Button
                as="a"
                href={post.url}
                target="_blank"
                rel="noopener noreferrer"
                leftIcon={
                  getPostTypeIcon(post) ? (
                    <Image
                      src={getPostTypeIcon(post)}
                      alt={`${post.type} icon`}
                      boxSize="20px"
                    />
                  ) : (
                    <ExternalLinkIcon />
                  )
                }
                size="sm"
                variant="outline"
                mt={2}
              >
                View Media <ExternalLinkIcon ml={2} />
              </Button>

              {/* Statistics as 2x2 Grid with Boxes and Text */}
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                mt={2}
                width="100%"
              >
                <GridItem>
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={2}
                    textAlign="center"
                  >
                    <Text fontSize="sm" color="gray.500">
                      Impressions
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {FormatNumber(post.impressions)}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={2}
                    textAlign="center"
                  >
                    <Text fontSize="sm" color="gray.500">
                      Comments
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {FormatNumber(post.comments)}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={2}
                    textAlign="center"
                  >
                    <Text fontSize="sm" color="gray.500">
                      Likes
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {FormatNumber(post.likes)}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={2}
                    textAlign="center"
                  >
                    <Text fontSize="sm" color="gray.500">
                      Shares
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {FormatNumber(post.shares)}
                    </Text>
                  </Box>
                </GridItem>
              </Grid>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CampaignPosts;
