import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Image,
  Box,
  HStack,
  VStack,
  IconButton,
  useOutsideClick,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { createPortal } from 'react-dom';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';

import instagramIcon from '../../assets/instagrampost.png';
import instagramReel from '../../assets/instagramreel.png';
import instagramStory from '../../assets/instagramstory.png';
import tiktokPostIcon from '../../assets/tiktok.png';

const allDeliverables = [
  { type: 'Instagram Story', icon: instagramStory },
  { type: 'Instagram Reel', icon: instagramReel },
  { type: 'Instagram Post', icon: instagramIcon },
  { type: 'TikTok Video', icon: tiktokPostIcon },
];

const Deliverables = ({ initialDeliverables = [], onChange, isEditable }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [floatingPosition, setFloatingPosition] = useState({ top: 0, left: 0 });
  const floatingRef = useRef(null);
  const buttonRefs = useRef([]);
  const [deliverables, setDeliverables] = useState(initialDeliverables);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setDeliverables(initialDeliverables);
  }, [initialDeliverables]);

  const getIconForDeliverable = (deliverableType) => {
    const deliverable = allDeliverables.find((d) => d.type === deliverableType);
    return deliverable ? deliverable.icon : null;
  };

  const handleButtonClick = (index) => {
    if (!isEditable) return;
    setExpandedIndex(expandedIndex === index ? null : index);

    const rect = buttonRefs.current[index].getBoundingClientRect();
    setFloatingPosition({
      top: rect.bottom + window.scrollY + 8,
      left: rect.left + window.scrollX,
    });
  };

  const handleAddButtonClick = () => {
    if (!isEditable) return;
    setIsAdding(!isAdding);

    const rect = buttonRefs.current[deliverables.length].getBoundingClientRect();
    setFloatingPosition({
      top: rect.bottom + window.scrollY + 8,
      left: rect.left + window.scrollX,
    });
  };

  const handleClose = () => {
    setExpandedIndex(null);
    setIsAdding(false);
  };

  const handleCountChange = (index, newCount) => {
    if (!isEditable) return;
    const updated = [...deliverables];
    updated[index].count = newCount;
    setDeliverables(updated);
    onChange(updated);
  };

  const handleRemoveDeliverable = (index) => {
    if (!isEditable) return;
    const updated = deliverables.filter((_, i) => i !== index);
    setDeliverables(updated);
    onChange(updated);
  };

  const handleSelectDeliverable = (deliverable) => {
    if (!isEditable) return;
    const newDeliverable = { type: deliverable.type, count: 1 };
    const updated = [...deliverables, newDeliverable];
    setDeliverables(updated);
    setIsAdding(false);
    onChange(updated);
  };

  useOutsideClick({
    ref: floatingRef,
    handler: handleClose,
  });

  const availableDeliverables = allDeliverables.filter(
    (d) => !deliverables.some((ud) => ud.type === d.type)
  );

  return (
    <Wrap spacing={2} maxW="340px">
      {deliverables.map((deliverable, index) => (
        <WrapItem key={index} position="relative">
          <Button
            ref={(el) => (buttonRefs.current[index] = el)}
            onClick={() => handleButtonClick(index)}
            leftIcon={
              <Image
                boxSize="20px"
                src={getIconForDeliverable(deliverable.type)}
                alt={deliverable.type}
              />
            }
            borderRadius="full"
            borderWidth="1px"
            bg="gray.50"
            cursor={isEditable ? 'pointer' : 'default'}
          >
            x{deliverable.count}
          </Button>
          {expandedIndex === index &&
            isEditable &&
            createPortal(
              <Box
                ref={floatingRef}
                position="absolute"
                top={`${floatingPosition.top}px`}
                left={`${floatingPosition.left}px`}
                bg="gray.50"
                p={2}
                pt={-1}
                borderRadius="xl"
                borderWidth="1px"
                boxShadow="lg"
                zIndex={1000}
                maxW="300px"
              >
                <HStack spacing={2} mt={2}>
                  <NumberInput
                    value={deliverable.count}
                    onChange={(_, valueNumber) =>
                      handleCountChange(index, valueNumber)
                    }
                    min={1}
                    max={99}
                    size="sm"
                    width="80px"
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <IconButton
                    aria-label="Remove deliverable"
                    icon={<CloseIcon />}
                    size="sm"
                    onClick={() => handleRemoveDeliverable(index)}
                  />
                </HStack>
              </Box>,
              document.body
            )}
        </WrapItem>
      ))}
      {isEditable && (
        <WrapItem position="relative">
          <IconButton
            ref={(el) => (buttonRefs.current[deliverables.length] = el)}
            aria-label="Add deliverable"
            icon={<AddIcon color="gray.300" />}
            size="sm"
            borderRadius="full"
            bg="white"
            _hover={{ bg: 'gray.100' }}
            onClick={handleAddButtonClick}
          />
          {isAdding &&
            availableDeliverables.length > 0 &&
            createPortal(
              <Box
                ref={floatingRef}
                position="absolute"
                top={`${floatingPosition.top}px`}
                left={`${floatingPosition.left}px`}
                bg="gray.50"
                p={2}
                borderRadius="xl"
                boxShadow="lg"
                zIndex={1000}
                maxW="300px"
                borderWidth="1px"
              >
                <VStack spacing={2}>
                  {availableDeliverables.map((deliverable, index) => (
                    <Button
                      key={index}
                      leftIcon={
                        <Image
                          boxSize="20px"
                          src={deliverable.icon}
                          alt={deliverable.type}
                        />
                      }
                      onClick={() => handleSelectDeliverable(deliverable)}
                      size="sm"
                      width="100%"
                      borderWidth="1px"
                      borderRadius="full"
                    >
                      {deliverable.type}
                    </Button>
                  ))}
                </VStack>
              </Box>,
              document.body
            )}
        </WrapItem>
      )}
    </Wrap>
  );
};

export default Deliverables;
